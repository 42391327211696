<template>
  <div class="flex flex-col min-h-screen bg-white">
    <div class="flex-1 flex flex-col h-full">
      <div
        id="navbar"
        class="border-0 border-b border-solid border-gray-200 mb-10"
      >
        <navbar-auth />
      </div>
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </div>
  </div>
</template>

<script>
import navbarAuth from "./navbar";
export default {
  components: { navbarAuth },
};
</script>