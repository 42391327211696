<template>
  <div class="bg-white">
    <div class="w-4/5 mx-auto">
      <nav class="flex items-center justify-between flex-wrap py-4 px-5">
        <div class="flex items-center flex-no-shrink mr-5">
          <router-link to="/">
            <img src="/logos/48/logo@2x.png" class="logo h-16" />
          </router-link>
        </div>
        <!-- Button Expand -->
        <div class="block sm:hidden">
          <button
            @click="open = !open"
            class="flex items-center px-3 py-2 border-0 bg-white"
          >
            <i class="fas fa-stream"></i>
          </button>
        </div>
        <!-- End Button -->
        <div
          :class="open ? 'block' : 'hidden'"
          class="w-full flex-grow sm:flex sm:items-center sm:w-auto"
        >
          <div
            class="text-xl sm:flex-grow flex items-center mb-3 lg:mb-0"
          ></div>

          <router-link
            v-if="!$auth.isAuthenticated()"
            :to="$route.name === 'pageRegister' ? '/login' : '/register'"
            class="px-10 rounded-full flex items-center cursor-pointer text-basex font-semibold bg-dokBlue-ultra border border-solid border-dokBlue-ultra text-white"
            style="height: 40px"
          >
            {{
              $route.name === "pageRegister"
                ? "Se connecter"
                : "Créer un compte"
            }}
          </router-link>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false
    };
  }
};
</script>

<style></style>
